









import { Component, Vue } from "vue-property-decorator";

type routerConfig ={
  path:string,
  name:string
}
@Component({
  name:'headBar',
  components: {},
})
export default class HeadBar extends Vue {
  pageList: Array<routerConfig> = [
    {
      path: "/page1",
      name: "Page1",
    },
    {
      path: "/page2",
      name: "Page2",
    },
    {
      path: "/page3",
      name: "Page3",
    },
  ];
}
