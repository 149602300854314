import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    foo: "foo",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
