<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height:calc(100vh - 180px);
}
</style>
