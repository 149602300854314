











import { Component, Vue } from "vue-property-decorator";
import Page1 from "../Page1/index.vue";
import HeadBar from "@/views/Componets/headBar.vue";

@Component({
  components: {
    Page1,
    HeadBar,
  },
})
export default class Home extends Vue {}
