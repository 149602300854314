

























































import { Component, Emit, Prop, PropSync, Vue } from "vue-property-decorator";
class hasBook {
  public a: any;
  constructor(a: any) {
    this.a = a;
  }
}
@Component({})
export default class Page1 extends Vue {
  @Prop()
  readonly data1!: string;
  @PropSync("formData")
  public data2: any;
  form: any = {
    name: "",
    region: "",
    date1: "",
    date2: "",
    delivery: false,
    type: [],
    resource: "",
    desc: "",
  };
  protected created() {
    let a = new hasBook(2);
    console.log(a.a);
  }
  public onSubmit() {
    console.log(this.form, "1231");
  }
  public isClass(){
   let newClass= class newClass{
      constructor(){
        console.log('创建新的类');
        return this
      }
    }
    return newClass
  }
}
