import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    components: { 
      default: Home 
    },
    redirect:"/page1",
    children: [
      {
        path: 'page1',
        name: 'page1',
        components: {
          default: () => import('@/views/Page1/index.vue')
        }
      },
      {
        path: 'page2',
        name: 'page2',
        components: {
          default: () => import('@/views/Page2/index.vue')
        }
      },
      {
        path: 'page3',
        name: 'page3',
        components: {
          default: () => import('@/views/Page3/index.vue')
        }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
